
.ant-layout {
  background: #fff;
}

.ant-card-meta-avatar {
  display: flex;
  align-items: center;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 2px;
}

.ant-btn-primary.outlined {
  color: @primary-color;
  background: #fff !important;
}

.ant-btn-primary.semi-outlined {
  color: @primary-color;
  background: #F0F6FA !important;
  border: 1px solid #CADAE6;
}

.ant-btn-primary:focus {
  background: @primary-color;
}

.ant-form-item-label {
  padding-bottom: 2px !important;
}

.ant-checkbox-inner {
  border-radius: 4px;
}

.custom-checkbox {
  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
  }
  .ant-checkbox-inner::after {
    width: 10px !important;
    height: 10px !important;
  }
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &::before {
    display: none;
  }
  &::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }
}

.ant-form-item-explain-error {
  margin-bottom: 10px;
}

.modal-pdf-viewer {
  .ant-modal {
    max-width: 100vw;
    .ant-modal-content {
      background-color: transparent;
    }
  }
}

.popover-profile {
  top: 42px !important;
  width: 320px;
  .ant-popover-arrow{
    display: none;
  }
}

.ant-menu-inline-collapsed {
  .ant-menu-item {
    margin: 8px;
    margin-top: 4px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 0 calc(50% - 32px / 2) !important;
    &.ant-menu-item-selected {
      border-color: inherit;
    }
  }
}

.certificate-collapse-panel {
  border-radius: 0 !important;
  border-top: 6px solid #F5F5F5 !important;
  .ant-collapse-header {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    .ant-collapse-expand-icon .anticon-right {
      right: 1.25rem;
    }
  }
  .ant-collapse-content-box {
    margin: 0 1.25rem;
    padding: 1rem 0 !important;
    border-top: 1px solid #E0E0E0 !important;
  }
}

.schedule-collapse-panel {
  border-radius: 0 !important;
  border-bottom: 4px solid #F5F5F5 !important;
  .ant-collapse-header {
    font-size: 16px !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    .ant-collapse-expand-icon .anticon-right {
      right: 1rem;
    }
  }
  .ant-collapse-content-box {
    border-top: 1px solid #E0E0E0 !important;
  }
}

.custom-alert {
  margin-left: 240px;
  &.collapsed {
    margin-left: 55px;
  }
  &.success {
    .ant-message-notice-content {
      background: #5396C7;
      color: #fff;
      width: 500px;
    }
  }
  &.error {
    .ant-message-notice-content {
      background: #FF3131;
      color: #fff;
      width: 500px;
    }
  }
  &.warning {
    .ant-message-notice-content {
      background: #faad14;
      color: #fff;
      width: 500px;
    }
  }
  .ant-message-custom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.input-view {
  &.ant-select-disabled {
    .ant-select-selector {
      color: #404040 !important;
      cursor: default !important;
    }
  }
  &.ant-picker-disabled {
    input {
      color: #404040 !important;
      cursor: default !important;
    }
  }
  &.ant-input-affix-wrapper-disabled, input {
    color: #404040 !important;
    cursor: default !important;
  }
}

.avatar-group-custom {
  .ant-avatar-group .ant-avatar:not(:first-child) {
    margin-left: -12px;
  }
  
  .ant-popover-inner-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 4px;
  }
  
  .ant-avatar-group-popover .ant-avatar + .ant-avatar {
    margin-left: 0;
  }
}