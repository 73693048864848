
.logo {
  padding: 0 12px;
  display: flex;
  align-items: center;
  border-right: 1px solid #E0E0E0;
}

.logo-title {
  font-size: 22px;
  font-weight: 500;
  color: #2D4C6E;
}

.app-header {
  background: #fff;
  border-bottom: 1px solid #E0E0E0;
}

.app-menu {
  border: 0;
}

.app-content {
  background: #F5F5F5;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.04);
  overflow: auto;
  border-left: 1px solid #E0E0E0;
  transition: all 0.2s;
  min-height: calc(100vh - 64px);
}

.pointer {
  cursor: pointer;
}

.login {
  min-height: 100vh;
  background: radial-gradient(82.61% 132.17% at 0% 0%, #BCE3FF 0%, #5396C7 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-y {
  display: flex;
  align-items: center;
}

.center-x {
  display: flex;
  align-items: center;
}

.tree-container {
  position: relative;
  width: 300px;
  margin: auto;
  .tree-action {
    visibility: hidden;
    position: absolute;
    padding-left: 2px;
    display: flex;
    margin-top: 8px;
    width: 100%;
    justify-content: center;
    z-index: 10;
  }
  &:hover {
    .ant-card {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .tree-action {
      visibility: visible;

    }
  }
}

.tab-item {
  padding: .75rem 0;
  text-align: center;
  cursor: pointer;
  color: #9E9E9E;
  &:hover {
    background-color: #5397c70e;
  }
  &.active {
    color: @primary-color;
    font-weight: 500;
    border-bottom: 3px solid @primary-color;
  }
}

input.input-view {
  color: #404040 !important;
  cursor: default !important;
}

.category-list {
  background-color: white;
  padding: 1rem;
  width: 250px;
  .category-item {
    &:hover {
      background-color: #f5fafe;
      cursor: pointer;
    }
    &.active {
      color: #5396C7;
      background: #F0F6FA;
      border-radius: 8px;
    }
  }
}

.sub-content {
  flex: 1;
  box-shadow: inset 4px 0 4px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem 1.25rem;
}

.input-search {
  background-color: #F0F6FA;
  input {
    background-color: #F0F6FA;
  }
}

.schedule-detail {
  border-left: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  background-color: #fff;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  overflow: hidden;
  height: calc(100vh - 64px);
  width: 0;
  &.active {
    width: 400px;
    overflow-y: scroll;
  }
}

.custom-popup-map {
  .leaflet-popup-content-wrapper {
    border-radius: 4px;
    cursor: pointer;
  }
  .leaflet-popup-content {
    margin: 2px 6px;
  }
  .leaflet-popup-tip {
    width: 14px;
    height: 14px;
  }
}